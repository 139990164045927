import React, { useState } from "react";
import "./App.scss";
import Nav from "./components/Nav";
import Home from "./components/Home";
import MobileContact from "./components/MobileContact";
import Photo from "./components/Photo";

import { useMediaQuery } from "react-responsive";

function App() {
  const [currentPage, setCurrentPage] = useState("home");
  const isMobile = useMediaQuery({ query: "(max-device-width: 800px)" });

  let displaySection;
  switch (currentPage) {
    case "contact":
      displaySection = isMobile ? <MobileContact /> : <Home />;
      break;
    case "home":
      displaySection = <Home />;
      break;
    case "photo":
      displaySection = <Photo />;
      break;
    default:
      displaySection = <Home />;
  }

  return (
    <div className={`App ${currentPage}Nav`}>
      <Nav currentPage={currentPage} setCurrentPage={setCurrentPage} />
      {displaySection}
    </div>
  );
}
export default App;
