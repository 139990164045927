import React from 'react';
import { Img } from 'react-image';

const Photo = () => {
  let nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72]

  const Div = <div style={{ background: 'blue', width: 300, height: 300 }} />;
  return (
    <div className="Photo">
      <div className="gallery">
        {nums.map(x => (
          <div key={x} className="imageWrapper">
            <Img
              src={require(`../assets/images/img-${x}.jpg`)} alt={x} 
              loader={Div}
              unloader={Div}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Photo;

