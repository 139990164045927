import React, { useState } from "react";
import img from "../assets/matthew-dillon-cohen.jpg";
import { useMediaQuery } from "react-responsive";

const Nav = ({ setCurrentPage, currentPage }) => {
  const [navToggled, setToggle] = useState();
  const isMobile = useMediaQuery({ query: "(max-device-width: 800px)" });

  return (
    <React.Fragment>
      {isMobile ? (
        <section className="mobileNav">
          <div className="mobileHeaderWrapper">
            <h1>mdc</h1>
          </div>
          <ul>
            <li
              onClick={() => setCurrentPage("home")}
              className={`${currentPage === "home" ? "check" : ""}`}
            >
              video
            </li>
            <li
              onClick={() => setCurrentPage("contact")}
              className={`${currentPage === "contact" ? "check" : ""}`}
            >
              contact
            </li>
            <li
              onClick={() => setCurrentPage("photo")}
              className={`${currentPage === "photo" ? "check" : ""}`}
            >
              photo
            </li>
            <a
              style={{ textDecoration: "none", color: "inherit" }}
              href="https://www.colorfulsets.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>Colorful Sets</li>
            </a>
          </ul>
        </section>
      ) : (
        <section
          className={`header-wrapper ${
            navToggled ? "navToggledHeaderWrapper" : ""
          }`}
        >
          <header>
            <h1
              onClick={() => setToggle(!navToggled)}
              className={navToggled ? "navToggled" : ""}
            >
              mdc
            </h1>
            <nav className={navToggled ? "marginRevert" : ""}>
              <ul>
                <li onClick={() => setCurrentPage("home")}>
                  video
                  <span className="locationIndicator">
                    <span
                      className={`${currentPage === "home" ? "check" : ""}`}
                    />
                  </span>
                </li>
                <li onClick={() => setCurrentPage("contact")}>
                  contact{" "}
                  <span className="locationIndicator">
                    <span
                      className={`${currentPage === "contact" ? "check" : ""}`}
                    />
                  </span>
                </li>
                <li
                  onClick={() => {
                    setCurrentPage("photo");
                  }}
                >
                  photo{" "}
                  <span className="locationIndicator">
                    <span
                      className={`${currentPage === "photo" ? "check" : ""}`}
                    />
                  </span>
                </li>
                <a
                  style={{ textDecoration: "none", color: "inherit" }}
                  href="https://www.colorfulsets.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>Colorful Sets</li>
                </a>
              </ul>
            </nav>
            <div
              className={`highlightedImgWrapper ${
                currentPage === "contact" ? "" : "d-none"
              }`}
            >
              <img src={img} alt="Matthew Dillon Cohen on a couch" />
              <h3 className="contact">
      matthew dillon cohen is a filmmaker and photo taker who hates heights, loves bubbling water, and is terrible at writing in the 3rd person.  <br />
                <br />
                my email is{" "}
                <a href="mailto:m@matthewdilloncohen.nyc">
                  m@matthewdilloncohen.nyc
                </a>
                <br /> <br />
                for commercials in the united states and united kingdom please reach out to the good folks at <a href="https://biscuitfilmworks.com/us/" target="_blank" rel="noopener noreferrer">biscuit fillmworks</a>.

                <br /><br />
                please be nice to&nbsp;me.
              </h3>
            </div>
          </header>
        </section>
      )}
    </React.Fragment>
  );
};

export default Nav;
